<template>
  <CCard>
    <CCardHeader>
      <strong>Usuario</strong>
    </CCardHeader>
    <CCardBody>
      <CForm>
        <CInput
          description="Nombre de usuario"
          label="Nombre de usuario"
          horizontal
          :value="user.username"
          disabled
        />
        <CInput
          description="¿Ha iniciado sesión?"
          label="¿Ha iniciado sesión?"
          horizontal
          :value="getLogIn()"
          disabled
        />
        <CRow>
          <CCol sm="3">
            Género
          </CCol>
          <CCol sm="3">
            <CInputRadio
              disabled
              checked
              :inline="true"
              :label="generos[user.gender]"
              horizontal
            />
          </CCol>
        </CRow>
        <CInput
          label="Fecha de nacimiento"
          description="Fecha de nacimiento"
          horizontal
          disabled
          :value="user.birthdate"
        />
        <CInput
          label="Categoría"
          description="Categoría"
          horizontal
          disabled
          :value="getCategoriaName()"
        />
        <CInput
          label="Cigarrillos"
          description="Cigarrillos"
          horizontal
          disabled
          :value="getCigarrillos()"
        />
        <CInput
          label="Premios"
          description="Premios"
          horizontal
          disabled
          :value="getPremios()"
        />
      </CForm>
      <div class="d-flex justify-content-center">
        <CButton
          class="btn btn-secondary"
          @click="volver()"
        >
          Volver
        </CButton>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import {getCategoriasData} from '../../utils/utils';
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";

/**
 * Función para conseguir los datos de un usuario dado por una id 
 * 
 * @param {number} id - El id del usuario a tomar los datos
 * @returns {Promise<object>} Promesa que al resolverse da los datos del usuario
 */
async function getUserData(id) {
  let data;
  const token = localStorage.token;
  if (id % 1 != 0) {
    this.$router.push("/user/");
  }
  await axios
    .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_USUARIOS + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((result) => {
      data = result.data;
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          window.location = "#/pages/login";
        }
        if (error.response.status == 404) {
          window.location = "#/404"
        }
        if (error.response.status == 500) {
          window.location = "#/500";
        }
      }
    });
  return data;
}

/**
 * Función para conseguir los datos de los cigarrillos de un usuario dado por una id 
 * 
 * @param {number} id - El id del usuario a tomar sus cigarrillos
 * @returns {Promise<object>} Promesa que al resolverse da los cigarrillos del usuario
 */
async function getCigarrillosUser(id) {
  let data;
  const token = localStorage.token;
  if (id % 1 != 0) {
    this.$router.push("/user/");
  }
  await axios
    .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_CIGARROS + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((result) => {
      data = result.data;
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          window.location = "#/pages/login";
        }
        if (error.response.status == 404) {
          data = "";
        }
        if (error.response.status == 500) {
          window.location = "#/500";
        }
      }
    });
  return data;
}

/**
 * Función para conseguir los premios de un usuario dado por una id 
 * 
 * @param {number} id - El id del usuario a tomar sus premios
 * @returns {Promise<object>} Promesa que al resolverse da los datos de los premios del usuario
 */
async function getPremiosUser(id) {
  let data;
  const token = localStorage.token;
  if (id % 1 != 0) {
    this.$router.push("/user/");
  }
  await axios
    .get(
      process.env.VUE_APP_URL + process.env.VUE_APP_URL_PREMIOS + "user/" + id,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((result) => {
      data = result.data;
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          window.location = "#/pages/login";
        }
        if (error.response.status == 404) {
          data = "";
        }
        if (error.response.status == 500) {
          window.location = "#/500";
        }
      }
    });
  return data;
}

export default {
  name: "EditarUsuario",
  data() {
    return {
      //Los generos son un array, ya que el 0 es hombre, el 1 es mujer, etc. Así cuando se vaya a llamar al dato, te dará directamente la información del genero
      generos: ["Hombre", "Mujer"],
      user: {},
      temp: 0,
      tempC: 0,
      tempP: 0,
      tempCig: 0,
      categorias: [],
      id: null,
      cigarros: null,
      premios: "",
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.recibirDatos();
    this.getCategorias();
    this.getPremios();
    this.getCigarrillos();
  },
  methods: {
    /**
     * Función para cargar los datos del usuario
     * en la variable user.
     */
    recibirDatos() {
      if (this.temp > 0) {
        return this.user;
      }
      let data = getUserData(this.id);
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.user = result;
        this.user.birthdate = this.user.birthdate.slice(0, 10);
      });
      this.temp++;
    },
    /**
     * Función para obtener los cigarrilos de un
     * usuario dado. Y cargarlos en la variable
     * cigarros
     * 
     * @returns {object} Los cigarros del usuario dado.
     */
    getCigarrillos() {
      if (this.tempCig > 0) {
        return this.cigarros;
      }
      let cigg_data = getCigarrillosUser(this.id);
      cigg_data.then((resulta) => {
        if(typeof resulta !== 'undefined') {
          this.cigarros = resulta.initial_cigarettes;
        }
      });
      this.tempCig++;
      return this.cigarros;
    },
    /**
     * Función para obtener los premios de un usuario dado
     * y cargarlos en la variable premios.
     * 
     * @returns {object} Los premios del usuario
     */
    getPremios() {
      if (this.tempP > 0) {
        return this.premios;
      }
      let prem = getPremiosUser(this.id);
      let premios1;
      let premio = "";
      prem.then((resultado) => {
        premios1 = resultado;
        if (typeof premios1 === 'undefined') {
          premio = "Este usuario no tiene premios";
        } else {
          for (let index = 0; index < premios1.length; index++) {
            const element = premios1[index].award_title;
            if (index == 0) {
              premio = element;
            } else {
              premio = premio + ", " + element;
            }
            this.user.premio = premio;
          }
          this.premios = premio;
        }
      });
      this.tempP++;
      return this.premios;
    },
    /**
     * Función para obtener las categoria y cargarlas
     * en la variable categorias.
     * 
     * @returns {object} Las categorías obtenidas
     */
    getCategorias() {
      if (this.tempC > 0) {
        return this.categorias;
      }
      let data = getCategoriasData();
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.categorias = result;
      });
      this.tempC++;
      return this.categorias;
    },
    /**
     * Función para obtener el nombre de la categoria del usuario.
     * 
     * @returns {string} El nombre de la categoría del usuario.
     */
    getCategoriaName() {
      this.categorias = this.getCategorias();
      let nombre;
      for (let index = 0; index < this.categorias.length; index++) {
        if (this.categorias[index].category_id == this.user.category) {
          nombre = this.categorias[index].category_name;
          break;
        }
      }
      return nombre;
    },
    getLogIn() {
      if (this.user.first_logged == 1) 
        return "El usuario si ha iniciado sesión por primera vez";

      return "El usuario no ha iniciado sesión por primera vez";
    },
    volver() {
      this.$router.push("/user/");
    },
  },
};
</script>
